import React from "react";
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';

const StartedHome = ({ title, subTitle }) => {
  const {t} = useTranslation();
  return (
    <>
      {/* Why People choose Mereos */}
      <div className="mb-5 mt-10 lg:mt-32 lg:mb-20">
        {
          title &&
          <div className="text-center font-sans text-3xl w-5/6 mx-auto lg:text-5xl font-bold">
            {title}
          </div>
        }
        {
          subTitle &&
          <div className={`font-medium text-sm md:text-xl  text-center mx-auto w-10/12 md:w-full text-gray-500`}>
            {subTitle}
          </div>
        }
      </div>
      <div className="font-sans flex flex-col mx-auto w-full">
        {/* section 1 */}
        <div className="flex flex-col-reverse md:flex-row justify-between w-full p-0 my-10 space-y-4">
          <div className="flex flex-col w-11/12 lg:w-5/12 px-4 md:p-4 space-y-4 items-start ">
            <div className="flex  justify-start">
              <img alt="get started" src="https://d2lxkizvrhu4im.cloudfront.net/icons/pc_icon_with_bg.svg" className="m-0 p-0"/>
            </div>
            <div className="text-2xl lg:text-3xl   font-semibold text-left">
              {t('deploy_your_secure_evaluations_in_click')}
            </div>
            <div className="text-gray-500 text-sm md:text-xl text-left ">
              {t('distribute_your_test')}
            </div>

            <Link
              to='/scalabilité'
              className=" flex flex-row items-baseline space-x-2"
            >
              {" "}
              <div className="text-yellow-500 text-sm md:text-xl font-semibold">
                {t('click_here_to_find_out_more')}
              </div>
              <img
                alt="arrow img"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                className="transform -rotate-90 w-3 m-0 p-0 -translate-y-0.5"
              />
            </Link>
          </div>
          <div className="flex md:hidden w-2/12"></div>
          <div className=" w-10/12 mx-auto lg:w-5/12 ">
            <img
            className="h-full m-0 p-0"
              alt="get started"
              src="https://d2lxkizvrhu4im.cloudfront.net/images/ceo_standing_infront_screen_during_videoCall.svg"
            />
          </div>
        </div>

        {/* section 2 */}
        <div className="flex flex-col md:flex-row justify-between p-0 my-10 space-y-2">
          <div className="w-10/12 mx-auto lg:w-5/12">
            <img
              alt="get started"
              src="https://d2lxkizvrhu4im.cloudfront.net/images/infinity_with_peoples_inside.svg"
              className="m-0 p-0 h-full"
            />
          </div>
          <div className="flex  md:hidden w-2/12 "></div>
          <div className="flex flex-col justify-center text-center md:text-left w-11/12  md:w-5/12 px-4 md:p-4  space-y-4">
            <div className="flex justify-start">
              <img
                alt="get started"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/bulb_dim_on_icon.svg"
                className="m-0"
              />
            </div>
            <div className="text-2xl md:text-3xl font-semibold  text-left">
              {t('take_advantage_of_mereos_flexibility')}
            </div>
            <div className="text-gray-500 text-sm md:text-xl text-left">
              {t('our_solution_give_you_all_the_flexibility')}
            </div>

            <Link
              to='/flexibilité'
              className=" flex flex-row items-baseline  space-x-2"
            >
              {" "}
              <div className="text-yellow-500 text-sm md:text-xl font-semibold">
                {t('click_here_to_find_out_more')}
              </div>
              <img
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                alt="arrow img"
                className="transform -rotate-90 w-3 m-0 p-0 -translate-y-0.5"
              />
            </Link>
          </div>     
        </div>

        {/* section 3 */}
        <div className="flex flex-col-reverse md:flex-row justify-between item-center  p-0 my-10 space-y-4">
          <div className="flex flex-col  text-center md:text-left w-11/12 lg:w-5/12 px-4 md:p-4 space-y-4">
            <div className="flex  justify-start">
              <img
                alt="get started"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/scholar_cap_with_bg_icon.svg"
                className="m-0 p-0"
              />
            </div>
            
            <div className=" text-2xl md:text-3xl font-semibold text-left">
              {t('win_the_war_for_talent')}
            </div>
            <div className="text-gray-500 text-sm md:text-xl text-left">
              {t('offer_your_candidates_a_5_star_experience')}
            </div>
            
            <Link
              to='/expérience-candidat'
              className=" flex flex-row items-baseline space-x-2 mt-10"
            >
              {" "}
              <div className="text-yellow-500  text-sm md:text-xl font-semibold">
                {t('click_here_to_find_out_more')}
              </div>
              <img
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                alt="arrow img"
                className="transform -rotate-90 w-3 m-0 p-0 -translate-y-0.5"
              />
            </Link>
          </div>
          <div className="w-2/12 "></div>
          <div className="w-10/12 mx-auto lg:w-5/12 h-full m-0 p-0">
            <img alt="get started" src="https://d2lxkizvrhu4im.cloudfront.net/images/five_stars_screen_with_people.svg" />
          </div>
        </div>

        {/* section 4 */}
        <div className="flex flex-col md:flex-row justify-between  space-y-2 md:space-y-4 p-0 my-10 space-y-4">
          <div className='absolute right-0'>
            <img
              alt="right star"
              src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
              className="hidden md:block self-end"
            />
          </div>
          <div className="w-10/12 mx-auto md:w-5/12">
            <img
              alt="get started"
              src="https://d2lxkizvrhu4im.cloudfront.net/images/graph_board.svg"
              className="m-0 p-0 h-full"
            />
          </div>
          <div className="w-2/12 "></div>
          <div className="flex flex-col justify-center text-center md:text-left w-11/12  md:w-5/12 px-4 md:p-4 space-y-4">
            <div className="flex justify-start">
              <img
                alt="get started"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/disc_icon.svg"
                className="m-0"
              />
            </div>
            <div className="text-2xl md:text-3xl font-semibold text-left">
              {t('save_time_and_money')}
            </div>
            <div className="text-gray-500 text-sm md:text-xl text-left">
              {t('we_reduce_hr_recruitment_time')}
            </div>

            <div className=" flex flex-row items-baseline space-x-2">
              <div className="text-yellow-500 text-sm md:text-xl font-semibold">
                <Link to='/demo'>
                  Demo
                </Link>
              </div>
                <img
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                  alt="arrow img"
                  className="transform -rotate-90 w-3 m-0 p-0 -translate-y-0.5"
                />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartedHome;
