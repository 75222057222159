import { Link } from "gatsby";
import React from "react";

import * as classes from "./products.module.scss";

const Products = ({
  title,
  description,
  products
}) => {
  return (
    <>
      <div className="flex flex-col space-y-3 lg:flex-row m-0 p-0 lg:flex-wrap  items-center justify-between space-y-5">
        <div className={` flex flex-col space-y-6 lg:space-y-3 justify-end md:justify-center border-box items-center md:items-start md:py-5 rounded-xl pb-5 lg:pb-0 lg: ${classes.minHeight}`}>
          <div className=" text-cneter md:text-left md:py-4 text-3xl md:text-5xl font-bold">
            {title}
          </div>
          <div className="text-grey-500 font-normal lg:font-medium text-center md:text-left text-sm md:text-xl ">
            {description}
          </div>
        </div>
        {
          products.map((product, index) => (
            <Link
              key={index}
              to={product.href}
              className={` flex flex-col space-y-3 justify-center border-box items-center border-2 border-yellow-200 p-6 rounded-xl  bg-yellow-50 hover:shadow-lg ${classes.minHeight}`}
            >
              <div>
                <img
                  alt="home page"
                  className="m-0 h-10 lg:h-12 p-0"
                  src={product.icon}
                />
              </div>
              <div className="text-xl lg:text-2xl font-semibold">
                {product.title}
              </div>
              <div className="text-sm lg:text-lg font-normal lg:font-medium text-gray-500  text-center">
                {product.description}
              </div>
            </Link>
          ))
        }
      </div>
    </>
  );
};

export default Products;
