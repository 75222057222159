import React from "react";
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';

const Table = ({comparisonData, headers, button}) => {
  const {t} = useTranslation();
  return (
    <>
      {/* desktop table */}
      {/* not mobile view  */}
      <div className="md:flex flex-row  mx-auto font-medium hidden ">
        {/* column 1 */}
        <div className="flex flex-col justify-center items-center  w-1/3">
          <div className="h-20 w-full border-b-2 border-yellow-50 my-0"> </div>
          {
            comparisonData.map((row, index) => (
              <div className="h-14 w-full border-b-2 border-yellow-50  items-center align-center flex flex-col justify-center">
                <div className="flex flex-row items-center justify-start font-medium text-lg w-full">
                  <img
                    alt="home page"
                    src={row.icon}
                    className="m-0 mr-4"
                  />
                  {row.name}
                </div>
              </div>
            ))
          }
          <div className="h-24 w-full border-b-2 border-transparent items-center align-center flex flex-col justify-center"></div>
        </div>
        {/* column 2 */}
        <div className="flex flex-col justify-center items-center w-1/3 border-2 border-yellow-200 bg-yellow-50  rounded-md hover:shadow-lg">
          <div className="border-b-2 h-20  w-full border-yellow-50 flex flex-col  justify-center items-center align-center">
            <div className="flex flex-row w-full justify-center items-center ">
              <img
                alt="home page"
                src="/logo.svg"
                className="w-8 m-0"
              />
              <div className="mx-4 font-extrabold text-xl">
                {t(headers[0])}
              </div>
            </div>
          </div>
          {
            comparisonData.map((row, index) => (
              <div className="border-b-2 border-yellow-50 items-center align-center h-14 flex flex-row  justify-center font-normal text-lg w-full">
                {row.mereos}
              </div>
            ))
          }
          {
            button &&
            <div className=" items-center align-center h-24 flex flex-row  justify-center font-medium text-lg w-full">
              <button className="bg-yellow-300 border-0 text-base font-semibold px-6 py-3 rounded-sm w-full mx-8 my-10">
                <Link to={button.href}>
                  {button.text}
                </Link>
              </button>
            </div>
          }
          
        </div>
        {/* cloumn 3 */}
        <div className="flex flex-col justify-center items-center w-1/3">
          <div className=" items-center align-center text-center border-b-2 border-yellow-50  align-center h-20 flex flex-row  justify-center font-semibold text-lg w-full">
            <span className="w-4/5">
              {t(headers[1])}
            </span>
          </div>
          {
            comparisonData.map((row, index) => (
              <div className=" items-center align-center border-b-2 border-yellow-50  align-center   h-14 flex flex-row  justify-center font-normal text-lg w-full">
                {row.others}
              </div>
            ))
          }
          <div className="h-24 w-full border-b-2 border-transparent items-center align-center"></div>
        </div>
      </div>

      {/* mobile view table */}
      <div className="flex md:hidden mx-5 flex-col text-center items-center justify-center ">
        <div className="flex flex-row h-14 items-center justify-between w-full">
          <div className=" flex flex-col justify-center w-1/2 text-center bg-yellow-50  h-20 border-2 border-yellow-200 border-b-0 rounded-t-lg ">
            <div className="flex flex-row w-full justify-center items-center space-x-2">
              <img
                alt="home page"
                src="/logo.svg"
                className="w-8 m-0"
              />
              <div className=" font-extrabold text-lg">
                {t(headers[0])}
              </div>
            </div>
          </div>
          <div className="font-bold flex flex-col justify-center w-1/2 text-center h-14 text-xs p-1">
            {t(headers[1])}
          </div>
        </div>

        {
          comparisonData.map((row, index) => (
            <>
              <div className="py-4">
                <div className="flex flex-row items-center justify-start font-medium text-sm w-full">
                  <img
                    alt="home page"
                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/tableIconWallet.svg"
                    className="m-0 mr-4"
                  />
                  {row.name}
                </div>
              </div>
      
              <div className="flex flex-row w-full">
                <div className="font-normal text-sm flex flex-col justify-center w-1/2 text-center bg-yellow-50 h-14 border-r-2 border-l-2 border-yellow-200">
                  {row.mereos}
                </div>
                <div className="flex flex-col justify-center w-1/2 text-center font-normal text-sm h-14 ">
                  {row.others}
                </div>
              </div>
            </>
          ))
        }
        {
          button &&
          <div className=" items-center align-center h-14 flex flex-row font-semibold justify-center text-sm w-full my-4">
            <button className="bg-yellow-300 border-0 px-7 py-3 rounded-md focus:outline-none">
              <Link to={button.href}>
                {button.text}
              </Link>
            </button>
          </div>
        }
      </div>
    </>
  );
};

export default Table;
