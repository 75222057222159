import React from "react";
import {useTranslation, withTranslation} from 'react-i18next';

import Mereos from "../what_is_Mereos/mereos";
import Review from "../reviews/reviews";
import Ready from "../ready/ready";
import Subscribe from "../subscribe/subscribe";
import StartedHome from "../get_started/started_home";
import Products from "../product/products";
import HomeTopBanner from "../top_banner/top_banner";
import Subscriptions from "../subscriptions";

const Home = () => {
  const { t } = useTranslation();
  const ReviewsData = [
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/h_test_1.jpg",
      name: "Sabine Romeu",
      designation: "Responsable RH chez Cours Legendre",
      reviewTitle: `${t('what_they_say_about_mereos')}`,
      review: `${t('it_was_becoming_increasingly_difficult')}`,
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/h_test_2.jpg",
      name: "Antoine Gadaud",
      designation: "Responsable Développement chez IGS-RH",
      reviewTitle: `${t('what_they_say_about_mereos')}`,
      review:`${t('with_mereos_our_selection_tests_are_now')}`,
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/h_test_3.jpg",
      name: "Rob Sumner",
      designation: "Responsible RH chez Cognizant",
      reviewTitle: `${t('what_they_say_about_mereos')}`,
      review: `${t('for_the_past_three_years_the_mereos_solution')}`,
    },
  ];
  
  const products = [
    {
      title: `${t('platform')}`,
      href: "/plateforme",
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/Window.svg",
      description: `${t('all_in_one_solution_for_managing')}`
    },
    {
      title: "Extension",
      href: "/extension",
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/extension_icon.svg",
      description: `${t('secure_module_that_integrates_seamlessly')}`
    },
    {
      title: "Lockdown Browser",
      href: "/lockdown-browser",
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/shield_icon_with_bg.svg",
      description: `${t('locking_the_candidate_test_environment')}`
    },
    {
      title: `${t('evaluation')}`,
      href: "/évaluation",
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/book_icon_with_bg.svg",
      description: `${t('innovative_customized_assessments_of_the_highest_scientific_quality')}`
    },
    {
      title: "Analyse",
      href: "/analyse",
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/analyse_icon_with_bg.svg",
      description: `${t('identifying_candidates_strengths_and_weaknesses')}`
    }
  ];

  const steps = [
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/pc_icon_with_bg.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/ceo_standing_infront_screen_during_videoCall.svg",
      title: `${t('deploy_your_secure_evaluations_in_click')}`,
      description: `${t('distribute_your_test')}`,
      button: {
        text: `${t('click_here_to_find_out_more')}`,
        href: "/scalabilité"
      }
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/bulb_dim_on_icon.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/infinity_with_peoples_inside.svg",
      title: `${t('take_advantage_of_mereos_flexibility')}`,
      description: `${t('our_solution_give_you_all_the_flexibility')}`,
      button: {
        text: `${t('click_here_to_find_out_more')}`,
        href: "/flexibilité"
      }
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/scholar_cap_with_bg_icon.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/five_stars_screen_with_people.svg",
      title: `${t("win_the_war_for_talent")}`,
      description: `${t('offer_your_candidates_a_5_star_experience')}`,
      button: {
        text: `${t('click_here_to_find_out_more')}`,
        href: "/expérience-candidat"
      }
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/disc_icon.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/graph_board.svg",
      title: `${t('save_time_and_money')}`,
      description: `${t('we_reduce_hr_recruitment_time')}`,
      button: {
        text: "Demo",
        href: "/demo"
      }
    },
  ];

  const comparisonData = [
    { name: `${t('fixed_fee_per_session')}`, mereos: '0€', others: '5 000 – 30 000€', icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/tableIconWallet.svg" },
    { name: `${t('geographical_limits')}`, mereos: `${t('no')}`, others: `${t('radius')} 50 km`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/MapTrifold.svg" },
    { name: `${t('time_limits')}`, mereos: `${t('no')}`, others: '5/7j, de 9h à 18h', icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/Hourglass.svg" },
    { name: `${t('candidate_experience')}`, mereos: `4,5 ${t('stars')}`, others: `2,7 ${t('stars')}`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/Book.svg" },
    { name: `${t('recruitment_period')}`, mereos: `2 ${t('days')}`, others: `14 ${t('days')}`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/BriefcaseMetal.svg" },
    { name: `${t('time_allocated_per_session')}`, mereos: '30 secondes', others: `${t('several_hours')}`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/Clock.svg" },
    { name: `${t('how_we_work')}`, mereos: 'Flexibles', others: `${t('frozen')}`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/Lightbulb.svg" }
  ];

  return (
    <>
      <div className={`conatiner font-sans`}>
        <HomeTopBanner
          pageTitle=""
          title={t("recruitment_enters_new_era")}
          description={t('mereos_simpley')}
          imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/home_banner_illus.svg"
          leftButton={() => alert("hi")}
        />

        {/* sub Container */}
        <div className={`mx-auto w-full md:w-5/6 `}>
          <div className="my-20 mx-auto w-11/12 space-y-10">
            <div>
              <div className="text-2xl md:text-5xl  text-center  font-bold">
                {t('mereos_combines_security_and_hyper_flexibility')}
              </div>
              <div
                className={`  font-medium text-sm md:text-xl  text-center mx-auto md:w-4/5 my-4 text-gray-500 `}
              >
               {t('what_mereous_offer')}
              </div>
            </div>
            <Mereos videoSrc="https://www.youtube.com/embed/dNsvifSHzD8" />
          </div>

          <div className="mt-20 lg:mt-40">
            <Subscriptions
              tag={t('comparative')}
              title={t('recruitment_needs_are_evolving')}
              description={t('free_yourself_from_any_constraints')}
              headers={[
                'Mereos',
                `${t('face_to_face_assessment_at_an_examination_center')}`
              ]}
              comparisonData={comparisonData}
              button={{
                text: `${t('schedule_a_demo')}`,
                href: '/demo'
              }}
            />
          </div>

          {/* Get_Started_section */}
          <div className="lg:my-20">
            <StartedHome
              title={t("partner_with_mereos")}
              data={steps}
            />
          </div>
        </div>

        {/* What_People_Say_section */}
        <div className=" md:my-10">
          <Review Data={ReviewsData} />
        </div>

        <div className={`mx-auto w-11/12 lg:w-10/12 `}>
          {/* Products_section */}
          <div className="md:my-10 mx-auto">
            <Products
              title={t('our_products')}
              description={t('find_out_what_mereos_can_do_for_you')}
              products={products}
            />
          </div>

          {/* Ready_to_get_started_section */}
          <div className=" mx-auto my-20">
            <Ready />
          </div>
        </div>

        {/* Bottom Banner */}
        <Subscribe />
      </div>
    </>
  );
};

export default withTranslation()(Home);
