import React from "react";
import { Link } from "gatsby";

import BannerWrapper from "./banner_wrapper";
// import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import * as classes from "./top_banner.module.scss";
import { useTranslation } from 'react-i18next';

const TopBanner = ({
  pageTitle,
  title,
  description,
  leftButton,
  rightButton,
  imageSrc,
  noReverse,
  clients,
}) => {
  // const breakpoints = useBreakpoint();
  const { t } = useTranslation();
  return (
    <>
      <BannerWrapper>
        <div className={` flex flex-col md:flex-row  justify-center items-center h-full  mx-auto ${classes.wrapper}`}>
          <div
            className={`flex lg:flex-row  justify-center items-center w-full ${
              noReverse ? "flex-col " : "flex-col-reverse"
            } ${classes.subWrapper} `}
          >
            <div className={`w-full lg:w-6/12 md:mb-32 lg:mb-0 flex flex-col lg:items-end  p-0 h-full ${classes.leftHalfWrapper}`}>
              <div className={`flex flex-col w-11/12 lg:w-11/12 xl:w-10/12  space-y-4 md:space-y-5 lg:space-y-6 xl:space-y-8 items-center lg:items-start  mb-20 md:mb-0`}>
                <div className={`text-sm md:text-lg font-bold text-center lg:text-left`}>
                  {pageTitle}
                </div>
                <div className={` w-11/12 sm:w-3/4 md:w-2/3 lg:w-11/12  xl:w-10/12  text-center lg:text-left font-extrabold ${classes.title}`}>
                  <h1>{title}</h1>
                </div>
                <div
                  className={`text-center  lg:text-left font-normal w-10/12 md:w-1/2 lg:w-full ${classes.description}`} 
                  dangerouslySetInnerHTML={{__html: description}}  
                >
                  {/* {`${description}`}  */}
                </div>
                <div className="">
                  <Link to="/free-trial">
                    <button  className={`${leftButton ? classes.button1 : "hidden"} `}>
                      {t('free_trials')}
                    </button>
                  </Link>
                  <Link to="/demo">
                    <button className={`${rightButton ? classes.button1 : "hidden"}`}>
                      Book a Demo &gt;
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className={`w-11/12  lg:w-7/12 bg-cover  bg-no-repeat p-0 my-5 md:my-0 h-full ${classes.rightHalfWrapper}`}>
              <img
                src={imageSrc}
                alt="img banner"
                className="mx-auto m-0 p-0 "
                width="100%"
                style={
                  clients
                    ? { maxHeight: "470px", maxWidth: "750px" }
                    : { maxHeight: "550px", maxWidth: "750px" }
                }
              />
            </div>
          </div>
        </div>
      </BannerWrapper>
    </>
  );
};

export default TopBanner;
