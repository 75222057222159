import React from "react";
import { withTranslation } from "react-i18next";

import Table from "../table/table";

const Subscriptions = ({t, tag, title, description, headers, comparisonData, button }) => {
    return (
        <>
            <div className="mb-16 text-center">
                <div className="text-xs lg:text-base text-gray-500 font-semibold my-4 uppercase">
                    {tag}
                </div>
                <div className="text-center font-sans">
                    <div className="text-3xl  lg:text-5xl font-bold my-3">
                        {title}
                    </div>
                    <div className="my-5 font-normal text-sm lg:text-xl text-gray-500 w-5/6 md:w-full mx-auto">
                        {description}
                    </div>
                </div>
            </div>

            <Table 
                headers={headers}
                comparisonData={comparisonData}
                button={button}
            />
        </>
    );
};

export default withTranslation()(Subscriptions);
